import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
// import Layout from '../components/Layout'
import Helmet from 'react-helmet'
import WhoWeArePageHero from '../components/WhoWeArePageHeroComponent'
// import WhoWeArePageContent from '../components/WhoWeArePageContentComponent'
import WhoWeAreTeam from '../components/WhoWeAreTeamComponent'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

import Favicon from '../favicon-32x32.png'
// import ogMeta from '../img/an_og_index.jpg'
import ogMeta from '../scss/images/_02_1.jpg'

export const PageTemplate = ({
  title,
  content,
  aboutUsData,
  aboutUsDataTeamAcf,
  homeElementsData,
}) => (
  <section className="section section--gradient">
    <WhoWeArePageHero
      whoWeAreSectionOne={
        aboutUsData.homepageWhoWeAreSection.whowearesectionone
      }
      whoWeAreSectionTwo={
        aboutUsData.homepageWhoWeAreSection.whowearesectiontwo
      }
    />


    <WhoWeAreTeam enteringAcfData={aboutUsData} enteringAcfTeam={aboutUsData} />
  </section>
)

PageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  aboutUsData: PropTypes.object,
  aboutUsDataTeamAcf: PropTypes.object,
  homeElementsData: PropTypes.object,
}

const Page = ({ data }) => {
  const { wpPage: page } = data
  return (
    <div>
      {/* <Helmet title={`${page.title} | Interior Design Studio`} /> */}
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${page.title} | Interior Design Studio`}</title>
        <link rel="canonical" href={`http://ananenadovic.com/${page.slug}`} />

        <meta name="description" content={`${page.content}`} />
        <link rel="icon" type="image/png" sizes="32x32" href={Favicon} />
        <meta
          property="og:title"
          content={`${page.title} | Interior Design Studio`}
        />

        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content={`https://ananenadovic.com/${page.slug}`}
        />
        <meta name="robots" content="index" />
        <meta property="og:image" content={ogMeta} />

        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:title"
          content={`${page.title} | Interior Design Studio`}
        />
        <meta name="twitter:description" content={`${page.content}`} />
        <meta name="twitter:image" content={ogMeta} />
      </Helmet>
      <Navbar />
       <PageTemplate
        title={page.title}
        content={page.content}
         aboutUsData={data.aboutUs}
         aboutUsDataTeamAcf={data.aboutUs.acfTeam}
         homeElementsData={data.homeElements}
      />
      <div className="stickTheFooter">
        <Footer />
      </div>
    </div>
  )
}

Page.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Page

export const pageQuery = graphql`
query PageById($id: String!) {
	wpPage(id: {eq: $id}) {
		title
		content
	}
	aboutUs: wpPage( template: {templateName: {eq: "About Us"}},
	 locale:{ locale: {eq: "en_US"}} ) {
		id
		title
		content
		homepageWhoWeAreSection {
			whowearesectionone
			whowearesectiontwo
		}
		acfTeam {
          teammembers {
            teammembertext
            teammemberimage {
              mediaItemUrl
			  localFile {
                childImageSharp {
                  original {
                    src
                    height
                    width
                  }
                }
              }
              mediaDetails {
                height
                width
                file
              }
            }
          }
        }
	}
  }
`
