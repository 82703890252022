import React, { Component } from 'react'
import PropTypes from 'prop-types'
// import TestImage from '../scss/images/egger-27-gray.jpg'
// import TestImage from '../scss/images/Ratko_Dnevna_zona_3.jpg'
import TestImage from '../scss/images/_02_1.jpg'
// import {staticQe}

class WhoWeArePageHero extends Component {
  render() {
    const { whoWeAreSectionOne, whoWeAreSectionTwo } = this.props

    return (
      <div className="heroAboutUsContentHolder">
        <div className="heroAboutUsImageHolder">
          <div className="customWrapper">
            <div className="bottomHalfText">
              <div className="whitetitleHolder">
                <h1 className="whiteTitleHalf">WE ARE</h1>
              </div>
            </div>
            <div
              className="blendModeHolder"
              style={{ backgroundImage: `url(${TestImage})` }}
            >
              <div className="topHalfText">
                <h1 className="coloredTitleHalf">WE ARE</h1>
              </div>
            </div>
          </div>
          <img src={TestImage} alt="" />
        </div>

        <div className="heroAboutUsTextGrid">
          <div className="topRightContent">
            <h1>ANA NENADOVIC ARCHITECTS</h1>
            <div dangerouslySetInnerHTML={{ __html: whoWeAreSectionOne }} />
          </div>
          <div className="bottomLeftContent">
            <div dangerouslySetInnerHTML={{ __html: whoWeAreSectionTwo }} />
          </div>
        </div>
      </div>
    )
  }
}

WhoWeArePageHero.propsType = {
  //   WhoWeArePageHero: PropTypes.object,
  whoWeAreSectionOne: PropTypes.string,
  whoWeAreSectionTwo: PropTypes.string,
}

export default WhoWeArePageHero
